import { SpotType } from 'src/types';

// Tattoo Spots
export const SPOTS: SpotType[] = [
	{
		link: '/dragon/kanji',
		title: 'Kanji',
		x: 0.43,
		y: 0.35,
	},
];
