import { SpotType } from 'src/types';

// Tattoo Spots
export const SPOTS: SpotType[] = [
	{
		link: '/koi/kanji',
		title: 'Kanji',
		x: 0.28,
		y: 0.28,
	},
];
